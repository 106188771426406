import { useSuspenseQuery } from "@tanstack/react-query";
import { getProjectPHPSettings } from "../openapi/api-client.ts";
import { LoadProjectPHPSettings } from "../errors/LoadProjectPHPSettings.ts";

export const useProjectPHPSettings = (
  projectId?: string,
  extensionInstanceId?: string,
) =>
  useSuspenseQuery({
    queryKey: ["projectPHPSettings", { projectId }],
    queryFn: async () => {
      if (!projectId) {
        throw new Error("project id not set");
      }

      if (!extensionInstanceId) {
        throw new Error("extension instance id not set");
      }

      const response = await getProjectPHPSettings({
        path: { projectId },
        header: { extensionInstanceId },
      });

      if (!response.data) {
        throw new LoadProjectPHPSettings(
          `error while fetching project php settings: ${response.error.message}`,
        );
      }

      return response.data;
    },
  });
