import { operations, paths } from "./generated-types";
import createClient from "openapi-fetch";

const client = createClient<paths>({
  baseUrl: import.meta.env.VITE_APP_API_BASE_URI,
  credentials: "include",
});

export const authenticationATReK = (
  body: operations["authentication.atrek"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST("/authentication/atrek", { body });
};

export const applyAppInstallationPHPSettings = (
  body: operations["appinstallation.apply-php-settings"]["requestBody"]["content"]["application/json"],
  params: operations["appinstallation.apply-php-settings"]["parameters"],
) => {
  return client.POST(
    "/projects/{projectId}/appinstallations/{appInstallationId}/php",
    { body, params, signal: AbortSignal.timeout(4000) },
  );
};

export const getProject = (params: operations["project.get"]["parameters"]) => {
  return client.GET("/projects/{projectId}", {
    params,
    signal: AbortSignal.timeout(2000),
  });
};

export const getProjectPHPSettings = (
  params: operations["project.get-php-settings"]["parameters"],
) => {
  return client.GET("/projects/{projectId}/php", {
    params,
    signal: AbortSignal.timeout(2000),
  });
};

export const getAppInstallationPHPSettings = (
  params: operations["appinstallation.get-php-settings"]["parameters"],
) => {
  return client.GET(
    "/projects/{projectId}/appinstallations/{appInstallationId}/php",
    { params, signal: AbortSignal.timeout(2000) },
  );
};

export const getProjectPHPStatistics = (
  params: operations["project.get-php-statistics"]["parameters"],
) => {
  return client.GET("/projects/{projectId}/php/statistics", {
    params,
    signal: AbortSignal.timeout(4000),
  });
};
