import { io } from "socket.io-client";

const url = new URL(import.meta.env.VITE_APP_API_BASE_URI);

console.log(url);

export const socket = io(url.origin, {
  path: `${url.pathname}socket.io`,
  autoConnect: false,
  transports: ["websocket"],
  withCredentials: true,
});
