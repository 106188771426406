import {
  Form,
  typedField,
} from "@mittwald/flow-react-components/react-hook-form";
import { SubmitHandler, useForm } from "react-hook-form";
import { useApplyAppInstallationPHPSettings } from "../hooks/useApplyAppInstallationPHPSettings.ts";
import NumberField from "@mittwald/flow-react-components/NumberField";
import ActionGroup from "@mittwald/flow-react-components/ActionGroup";
import Button from "@mittwald/flow-react-components/Button";
import Label from "@mittwald/flow-react-components/Label";
import Select, { Option } from "@mittwald/flow-react-components/Select";
import { useAppInstallationPHPSettings } from "../hooks/useAppInstallationPHPSettings.ts";
import Text from "@mittwald/flow-react-components/Text";
import Heading from "@mittwald/flow-react-components/Heading";
import RadioGroup, {
  RadioButton,
} from "@mittwald/flow-react-components/RadioGroup";
import ContextualHelp, {
  ContextualHelpTrigger,
} from "@mittwald/flow-react-components/ContextualHelp";
import Link from "@mittwald/flow-react-components/Link";
import { IconTerminate } from "@mittwald/flow-react-components/Icons";
import ColumnLayout from "@mittwald/flow-react-components/ColumnLayout";
import Header from "@mittwald/flow-react-components/Header";
import Modal, { ModalTrigger } from "@mittwald/flow-react-components/Modal";
import Content from "@mittwald/flow-react-components/Content";
import { useProject } from "../hooks/useProject.ts";
import { useProjectPHPSettings } from "../hooks/useProjectPHPSettings.ts";
import Section from "@mittwald/flow-react-components/Section";
import InlineCode from "@mittwald/flow-react-components/InlineCode";
import { useState } from "react";
import { SavingIniError } from "../errors/SavingIniError.ts";
import { ErrorLog } from "./ErrorLog.tsx";

export interface FormInputs {
  memoryLimit: number;
  timezone: string;
  opcacheEnabled: string;
  maxInputVars: number;
  postMaxSize: number;
  displayErrors: string;
  logErrors: string;
  maxExecutionTime: number;
  uploadMaxFilesize: number;
  maxInputTime: number;
  errorLog: string;
  errorReporting: string;
}

export const TestForm = (props: {
  projectId: string;
  appInstallationId: string;
  extensionInstanceId: string;
}) => {
  const [errorOnSubmit, setErrorOnSubmit] = useState<Error | undefined>(
    undefined,
  );

  if (errorOnSubmit) {
    throw errorOnSubmit;
  }

  const saveMutation = useApplyAppInstallationPHPSettings(
    props.projectId,
    props.appInstallationId,
    props.extensionInstanceId,
  );

  const project = useProject(props.projectId, props.extensionInstanceId);

  const appInstallation = project.data.appInstallations.find(
    (a) => a.id == props.appInstallationId,
  );

  if (!appInstallation) {
    throw new Error("app installation not found in project");
  }

  const projectPHPSettings = useProjectPHPSettings(
    props.projectId,
    props.extensionInstanceId,
  );

  const appInstallationPHPSettings = useAppInstallationPHPSettings(
    props.projectId,
    props.appInstallationId,
    props.extensionInstanceId,
  );

  const defaultValues: FormInputs = {
    memoryLimit: appInstallationPHPSettings.data.memoryLimit ?? NaN,
    timezone: appInstallationPHPSettings.data.timezone ?? "",
    opcacheEnabled:
      appInstallationPHPSettings.data.opcacheEnabled == undefined
        ? ""
        : appInstallationPHPSettings.data.opcacheEnabled
          ? "on"
          : "off",
    displayErrors:
      appInstallationPHPSettings.data.displayErrors == undefined
        ? ""
        : appInstallationPHPSettings.data.displayErrors
          ? "on"
          : "off",
    logErrors:
      appInstallationPHPSettings.data.logErrors == undefined
        ? ""
        : appInstallationPHPSettings.data.logErrors
          ? "on"
          : "off",
    errorLog: appInstallationPHPSettings.data.errorLog ?? "",
    maxInputVars: appInstallationPHPSettings.data.maxInputVars ?? NaN,
    maxExecutionTime: appInstallationPHPSettings.data.maxExecutionTime ?? NaN,
    maxInputTime: appInstallationPHPSettings.data.maxInputTime ?? NaN,
    postMaxSize: appInstallationPHPSettings.data.postMaxSize ?? NaN,
    uploadMaxFilesize: appInstallationPHPSettings.data.uploadMaxFilesize ?? NaN,
    errorReporting: appInstallationPHPSettings.data.errorReporting ?? "",
  };

  const form = useForm<FormInputs>({
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const result = await saveMutation.mutateAsync({
      timezone: data.timezone != "" ? data.timezone : undefined,
      memoryLimit: !isNaN(data.memoryLimit) ? data.memoryLimit : undefined,
      maxInputVars: !isNaN(data.maxInputVars) ? data.maxInputVars : undefined,
      maxInputTime: !isNaN(data.maxInputTime) ? data.maxInputTime : undefined,
      maxExecutionTime: !isNaN(data.maxExecutionTime)
        ? data.maxExecutionTime
        : undefined,
      errorLog: data.errorLog != "" ? data.errorLog : undefined,
      displayErrors:
        data.displayErrors != "" ? data.displayErrors == "on" : undefined,
      logErrors: data.logErrors != "" ? data.logErrors == "on" : undefined,
      opcacheEnabled:
        data.opcacheEnabled != "" ? data.opcacheEnabled == "on" : undefined,
      uploadMaxFilesize: !isNaN(data.uploadMaxFilesize)
        ? data.uploadMaxFilesize
        : undefined,
      postMaxSize: !isNaN(data.postMaxSize) ? data.postMaxSize : undefined,
      errorReporting:
        data.errorReporting != "" ? data.errorReporting : undefined,
    });

    if (result.error) {
      setErrorOnSubmit(new SavingIniError(`${result.error.message}`));
    }
  };

  const Field = typedField(form);

  const errorLogValues: Array<{ value: string; label: string }> = [
    {
      value: "",
      label: `Projektstandard${
        projectPHPSettings.data.errorLog
          ? ` (${projectPHPSettings.data.errorLog})`
          : ""
      }`,
    },
    {
      value: `/logs/php_errors_${appInstallation.shortId}.log`,
      label: `/logs/php_errors_${appInstallation.shortId}.log`,
    },
    { value: "/logs/php_errors.log", label: "/logs/php_errors.log" },
  ];

  if (!errorLogValues.find((e) => e.value == defaultValues.errorLog)) {
    errorLogValues.push({
      value: defaultValues.errorLog,
      label: defaultValues.errorLog,
    });
  }

  const timezoneValues: Array<{ value: string; label: string }> = [
    {
      value: "",
      label: `Projektstandard${
        projectPHPSettings.data.timezone
          ? ` (${projectPHPSettings.data.timezone})`
          : ""
      }`,
    },
    {
      value: `Europe/Berlin`,
      label: `Europe/Berlin`,
    },
    { value: "Europe/Vienna", label: "Europe/Vienna" },
    { value: "Europe/Zurich", label: "Europe/Zurich" },
  ];

  if (!timezoneValues.find((e) => e.value == defaultValues.timezone)) {
    timezoneValues.push({
      value: defaultValues.timezone,
      label: defaultValues.timezone,
    });
  }

  const errorReportingValues: Array<{ value: string; label: string }> = [
    {
      value: "",
      label: `Projektstandard${
        projectPHPSettings.data.errorReporting
          ? ` (${projectPHPSettings.data.errorReporting})`
          : ""
      }`,
    },
    {
      value: `E_ALL`,
      label: `Entwicklung (alle Meldungen)`,
    },
    {
      value: "E_ALL & ~E_NOTICE & ~E_DEPRECATED & ~E_STRICT",
      label: "Produktiv (nur relevante Meldungen)",
    },
  ];

  if (
    !errorReportingValues.find((e) => e.value == defaultValues.errorReporting)
  ) {
    errorReportingValues.push({
      value: defaultValues.errorReporting,
      label: defaultValues.errorReporting,
    });
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Section>
        <Heading>App-spezifische PHP-Einstellungen</Heading>
        <Text>
          Jegliche Änderungen, die du hier machst, wirken sich nur auf die
          ausgewählte App aus.
        </Text>

        <Heading level={3}>
          Allgemeines
          <ContextualHelpTrigger>
            <Button />
            <ContextualHelp>
              <Heading>Allgemeines</Heading>
              <Text>
                <ul>
                  <li>
                    Die eingestellte <strong>Zeitzone</strong>{" "}
                    <InlineCode>date.timezone</InlineCode> dient zur Nutzung in
                    allen Zeit- und Datumsfunktionen von PHP.
                  </li>
                  <li>
                    Der <strong>OPcache</strong>{" "}
                    <InlineCode>opcache.enable</InlineCode> erhöht die
                    PHP-Performance durch das Speichern des vorkompilierten
                    Bytecodes im Arbeitsspeicher.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.php.net/manual/de/ini.list.php"
                target="_blank"
              >
                Mehr erfahren
              </Link>
            </ContextualHelp>
          </ContextualHelpTrigger>
        </Heading>
        <ColumnLayout rowGap="l" columnGap="l" m={[1, 1]}>
          <Field name={"timezone"}>
            <Select isRequired isDisabled={saveMutation.isPending}>
              <Label>Zeitzone (date.timezone)</Label>
              {timezoneValues.map((e) => (
                <Option key={e.value} value={e.value}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Field>
        </ColumnLayout>
        <Field name={"opcacheEnabled"}>
          <RadioGroup isDisabled={saveMutation.isPending}>
            <Label>OPcache aktivieren (opcache.enable)</Label>
            <RadioButton value="on">OPcache aktiviert</RadioButton>
            <RadioButton value="off">OPcache deaktiviert</RadioButton>
            <RadioButton value="">
              Projektstandard{" "}
              {projectPHPSettings.data.opcacheEnabled != undefined
                ? ` (${projectPHPSettings.data.opcacheEnabled ? "aktiviert" : "deaktiviert"})`
                : ""}
            </RadioButton>
          </RadioGroup>
        </Field>

        <Header>
          <Heading level={3}>
            Fehler & Logs
            <ContextualHelpTrigger>
              <Button />
              <ContextualHelp>
                <Heading>Fehler & Logs</Heading>
                <Text>
                  <ul>
                    <li>
                      Die Einstellung <strong>Fehler anzeigen</strong>{" "}
                      <InlineCode>display_errors</InlineCode> bestimmt, ob PHP
                      Fehler direkt im Browser angezeigt werden sollen. Für
                      Live-Systeme sollte diese Option deaktiviert werden, um
                      Sicherheitsrisiken zu minimieren.
                    </li>
                    <li>
                      Die Option <strong>Fehler in Log-Datei speichern</strong>{" "}
                      <InlineCode>log_errors</InlineCode> aktiviert das
                      Protokollieren von Fehlern in einer Log-Datei, anstatt sie
                      im Browser auszugeben. Empfohlen für Live-Systeme, um
                      Fehler zu diagnostizieren, ohne Sicherheitslücken zu
                      schaffen.
                    </li>
                    <li>
                      Mit dem <strong>Pfad der Log-Datei</strong>{" "}
                      <InlineCode>error_log</InlineCode> definiert den Pfad zur
                      Datei, in die Fehlerprotokolle geschrieben werden sollen.
                    </li>
                    <li>
                      Die Auswahl an{" "}
                      <strong>protokollierten Fehlertypen</strong>{" "}
                      <InlineCode>error_reporting</InlineCode> gibt an, welche
                      Fehlertypen genau protokolliert werden sollen.
                    </li>
                  </ul>
                </Text>
                <Link
                  href="https://www.php.net/manual/de/ini.list.php"
                  target="_blank"
                >
                  Mehr erfahren
                </Link>
              </ContextualHelp>
            </ContextualHelpTrigger>
          </Heading>
          <ModalTrigger>
            <Button
              color="primary"
              variant="soft"
              isDisabled={
                appInstallationPHPSettings.data.logErrors == false ||
                (appInstallationPHPSettings.data.logErrors == undefined &&
                  projectPHPSettings.data.logErrors != true)
              }
            >
              Live Error-Log anzeigen (beta)
            </Button>
            <Modal offCanvas size="m">
              <Heading>
                <IconTerminate />
                Live Error-Log (beta)
              </Heading>
              <Content>
                <Section>
                  <ErrorLog
                    extensionInstanceId={props.extensionInstanceId}
                    appInstallationId={props.appInstallationId}
                    projectId={props.projectId}
                  />
                </Section>
              </Content>
            </Modal>
          </ModalTrigger>
        </Header>
        <Field name={"displayErrors"}>
          <RadioGroup isDisabled={saveMutation.isPending}>
            <Label>Fehler anzeigen (display_errors)</Label>
            <RadioButton value="on">Fehler anzeigen</RadioButton>
            <RadioButton value="off">Fehler ausblenden</RadioButton>
            <RadioButton value="">
              Projektstandard
              {projectPHPSettings.data.displayErrors != undefined
                ? ` (${projectPHPSettings.data.displayErrors ? "anzeigen" : "ausgeblendet"})`
                : ""}
            </RadioButton>
          </RadioGroup>
        </Field>
        <Field name={"logErrors"}>
          <RadioGroup isDisabled={saveMutation.isPending}>
            <Label>Fehler in Log-Datei speichern (log_errors)</Label>
            <RadioButton value="on">Logs speichern</RadioButton>
            <RadioButton value="off">Logs nicht speichern</RadioButton>
            <RadioButton value="">
              Projektstandard
              {projectPHPSettings.data.logErrors != undefined
                ? ` (${projectPHPSettings.data.logErrors ? "speichern" : "nicht speichern"})`
                : ""}
            </RadioButton>
          </RadioGroup>
        </Field>
        <ColumnLayout rowGap="l" columnGap="l" m={[1, 1]}>
          <Field name={"errorLog"}>
            <Select isRequired isDisabled={saveMutation.isPending}>
              <Label>Pfad der Log-Datei (error_log)</Label>
              {errorLogValues.map((e) => (
                <Option key={e.value} value={e.value}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Field>
          <Field name={"errorReporting"}>
            <Select isRequired isDisabled={saveMutation.isPending}>
              <Label>Protokollierte Fehlertypen (error_reporting)</Label>
              {errorReportingValues.map((e) => (
                <Option key={e.value} value={e.value}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Field>
        </ColumnLayout>

        <Heading level={3}>
          Zeitlimits
          <ContextualHelpTrigger>
            <Button />
            <ContextualHelp>
              <Heading>Zeitlimits</Heading>
              <Text>
                <ul>
                  <li>
                    Die <strong>maximale Ausführungszeit</strong>{" "}
                    <InlineCode>max_execution_time</InlineCode> gibt an, wie
                    lange ein Skript ausgeführt werden darf, bevor das Beenden
                    erzwungen wird.
                  </li>
                  <li>
                    Die <strong>maximale Eingabezeit</strong>{" "}
                    <InlineCode>max_input_time</InlineCode> beschreibt die Zeit,
                    die PHP benötigen darf, um Eingaben zu parsen.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.php.net/manual/de/ini.list.php"
                target="_blank"
              >
                Mehr erfahren
              </Link>
            </ContextualHelp>
          </ContextualHelpTrigger>
        </Heading>
        <ColumnLayout rowGap="l" columnGap="l" m={[1, 1]}>
          <Field name={"maxExecutionTime"}>
            <NumberField
              isRequired
              isDisabled={saveMutation.isPending}
              formatOptions={{
                style: "unit",
                unit: "second",
              }}
              step={60}
            >
              <Label>Maximale Ausführungszeit (max_execution_time)</Label>
            </NumberField>
          </Field>
          <Field name={"maxInputTime"}>
            <NumberField
              isRequired
              isDisabled={saveMutation.isPending}
              formatOptions={{
                style: "unit",
                unit: "second",
              }}
              step={60}
            >
              <Label>Maximale Eingabezeit (max_input_time)</Label>
            </NumberField>
          </Field>
        </ColumnLayout>

        <Heading level={3}>
          Speicher- und Übertragungslimits
          <ContextualHelpTrigger>
            <Button />
            <ContextualHelp>
              <Heading>Speicher- und Übertragungslimits</Heading>
              <Text>
                <ul>
                  <li>
                    Die <strong>maximale Dateigröße via POST</strong>{" "}
                    <InlineCode>post_max_size</InlineCode> definiert die Größe
                    für Daten, die über eine POST-Anfrage übertragen werden
                    können. Das ist wichtig für Formulare und Datei-Uploads.
                  </li>
                  <li>
                    Die <strong>maximale Dateigröße via HTTP</strong>{" "}
                    <InlineCode>upload_max_filesize</InlineCode> definiert die
                    Dateigröße für Uploads über HTTP. Das ist essentiell für
                    Systeme, die Dateiuploads unterstützen, wie CMS und
                    E-Commerce-Plattformen.
                  </li>
                  <li>
                    Die <strong>maximale Speichermenge</strong>{" "}
                    <InlineCode>memory_limit</InlineCode> gibt an, wie viel
                    Speicher ein PHP-Skript maximal verwenden darf, bevor es
                    abgebrochen wird.
                  </li>
                  <li>
                    Die <strong>maximale Anzahl von Variablen</strong>{" "}
                    <InlineCode>max_input_vars</InlineCode> steuert, wie viele
                    GET-, POST- oder Cookie-Eingaben akzeptiert werden. Dies ist
                    wichtig für Systeme mit umfangreichen Formularen oder
                    komplexen Datenstrukturen.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.php.net/manual/de/ini.list.php"
                target="_blank"
              >
                Mehr erfahren
              </Link>
            </ContextualHelp>
          </ContextualHelpTrigger>
        </Heading>
        <ColumnLayout rowGap="l" columnGap="l" m={[1, 1]}>
          <Field name={"postMaxSize"}>
            <NumberField
              isRequired
              isDisabled={saveMutation.isPending}
              formatOptions={{
                style: "unit",
                unit: "megabyte",
              }}
              step={128}
            >
              <Label>Maximale Dateigröße via POST (post_max_size)</Label>
            </NumberField>
          </Field>
          <Field name={"uploadMaxFilesize"}>
            <NumberField
              isRequired
              isDisabled={saveMutation.isPending}
              formatOptions={{
                style: "unit",
                unit: "megabyte",
              }}
              step={128}
            >
              <Label>Maximale Dateigröße via HTTP (upload_max_filesize)</Label>
            </NumberField>
          </Field>
          <Field name={"memoryLimit"}>
            <NumberField
              isRequired
              isDisabled={saveMutation.isPending}
              formatOptions={{
                style: "unit",
                unit: "megabyte",
              }}
              step={128}
            >
              <Label>Maximale Speichermenge (memory_limit)</Label>
            </NumberField>
          </Field>
          <Field name={"maxInputVars"}>
            <NumberField
              isRequired
              isDisabled={saveMutation.isPending}
              step={500}
            >
              <Label>Maximale Anzahl von Variablen (max_input_vars)</Label>
            </NumberField>
          </Field>
        </ColumnLayout>
        <ActionGroup>
          <Button type="submit">Einstellungen speichern</Button>
        </ActionGroup>
      </Section>
    </Form>
  );
};
