import "@mittwald/flow-react-components/all.css";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Suspense } from "react";
import { openobserveRum } from "@openobserve/browser-rum";
import { openobserveLogs } from "@openobserve/browser-logs";

const options = {
  clientToken: import.meta.env.VITE_OPENOBSERVE_CLIENT_TOKEN,
  applicationId: import.meta.env.VITE_OPENOBSERVE_APPLICATION_ID,
  site: import.meta.env.VITE_OPENOBSERVE_URL,
  service: import.meta.env.VITE_OPENOBSERVE_APPLICATION_NAME,
  env: import.meta.env.MODE,
  version: import.meta.env.VITE_OPENOBSERVE_APPLICATION_VERSION,
  organizationIdentifier: import.meta.env.VITE_OPENOBSERVE_ORG,
  insecureHTTP: false,
  apiVersion: "v1",
};

if (window.location.hostname != "localhost") {
  openobserveRum.init({
    applicationId: options.applicationId,
    clientToken: options.clientToken,
    site: options.site,
    organizationIdentifier: options.organizationIdentifier,
    service: options.service,
    env: options.env,
    version: options.version,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    apiVersion: options.apiVersion,
    insecureHTTP: options.insecureHTTP,
    defaultPrivacyLevel: "allow",
  });

  openobserveRum.startSessionReplayRecording();

  openobserveLogs.init({
    clientToken: options.clientToken,
    site: options.site,
    organizationIdentifier: options.organizationIdentifier,
    service: options.service,
    env: options.env,
    version: options.version,
    forwardErrorsToLogs: true,
    insecureHTTP: options.insecureHTTP,
    apiVersion: options.apiVersion,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const projectId = urlParams.get("projectId");
  const appInstallationId = urlParams.get("appInstallationId");
  const extensionInstanceId = urlParams.get("extensionInstanceId");

  openobserveRum.setUser({
    id: userId != null ? userId : undefined,
  });

  openobserveRum.setGlobalContext({
    projectId,
    appInstallationId,
    extensionInstanceId,
  });
}

export const App = () => {
  return (
    <>
      <ScrollRestoration />
      <Suspense fallback={<>Loading...</>}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default App;
