import ColumnLayout from "@mittwald/flow-react-components/ColumnLayout";
import Skeleton from "@mittwald/flow-react-components/Skeleton";

export const FormSkeleton = () => {
  return (
    <>
      <Skeleton style={{ height: "40px" }} />
      <ColumnLayout rowGap="l" columnGap="l" m={[1, 1]}>
        <Skeleton style={{ height: "40px" }} />
        <Skeleton style={{ height: "40px" }} />
      </ColumnLayout>
      <Skeleton style={{ height: "40px" }} />
      <Skeleton style={{ height: "40px" }} />
      <ColumnLayout rowGap="l" columnGap="l">
        <Skeleton style={{ height: "40px" }} />
        <Skeleton style={{ height: "40px" }} />
        <Skeleton style={{ height: "40px" }} />
      </ColumnLayout>
      <Skeleton style={{ height: "40px" }} />
      <ColumnLayout rowGap="l" columnGap="l" m={[1, 1]}>
        <Skeleton style={{ height: "40px" }} />
        <Skeleton style={{ height: "40px" }} />
      </ColumnLayout>
      <Skeleton style={{ height: "40px" }} />
      <Skeleton style={{ height: "40px" }} />
      <ColumnLayout rowGap="l" columnGap="l">
        <Skeleton style={{ height: "40px" }} />
        <Skeleton style={{ height: "40px" }} />
        <Skeleton style={{ height: "40px" }} />
      </ColumnLayout>
    </>
  );
};
