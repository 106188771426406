import { useSuspenseQuery } from "@tanstack/react-query";
import { getAppInstallationPHPSettings } from "../openapi/api-client.ts";
import { LoadAppPHPSettingsError } from "../errors/LoadAppPHPSettingsError.ts";

export const useAppInstallationPHPSettings = (
  projectId?: string,
  appInstallationId?: string,
  extensionInstanceId?: string,
) =>
  useSuspenseQuery({
    queryKey: ["appInstallationPHPSettings", { projectId, appInstallationId }],
    queryFn: async () => {
      if (!projectId) {
        throw new Error("project id not set");
      }

      if (!appInstallationId) {
        throw new Error("app installation id not set");
      }

      if (!extensionInstanceId) {
        throw new Error("extension instance id not set");
      }

      const response = await getAppInstallationPHPSettings({
        path: { projectId, appInstallationId },
        header: { extensionInstanceId },
      });

      if (!response.data) {
        throw new LoadAppPHPSettingsError(
          `error while fetching app php settings: ${response.error.message}, app installation id: ${appInstallationId}`,
        );
      }

      return response.data;
    },
  });
