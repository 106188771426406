import { useProject } from "../hooks/useProject.ts";
import RadioGroup, {
  RadioButton,
} from "@mittwald/flow-react-components/RadioGroup";
import Label from "@mittwald/flow-react-components/Label";
import Content from "@mittwald/flow-react-components/Content";
import Text from "@mittwald/flow-react-components/Text";
import Alert from "@mittwald/flow-react-components/Alert";
import Heading from "@mittwald/flow-react-components/Heading";
import Link from "@mittwald/flow-react-components/Link";
import { useEffect, useState } from "react";

export const TempAppSelection = (props: {
  projectId: string;
  extensionInstanceId: string;
  appInstallationSelected: (appInstallationId: string) => void;
}) => {
  const project = useProject(props.projectId, props.extensionInstanceId);
  const [selectedAppInstallation, setSelectedAppInstallation] = useState<
    string | undefined
  >();

  useEffect(() => {
    const projectsWithPHP = project.data.appInstallations.filter(
      (a) => !!a.phpVersion,
    );

    if (projectsWithPHP.length == 1) {
      setSelectedAppInstallation(projectsWithPHP[0].id);
    }
  }, [project, props.appInstallationSelected]);

  useEffect(() => {
    if (selectedAppInstallation) {
      props.appInstallationSelected(selectedAppInstallation);
    }
  }, [selectedAppInstallation]);

  return (
    <>
      {project.data.appInstallations.length == 0 && (
        <Alert>
          <Heading>Keine Apps gefunden</Heading>
          <Content>
            In deinem Projekt sind keine Apps vorhanden. Bitte installiere eine
            App, um die PHP-Einstellungen konfigurieren zu können.
            <Link
              href={`https://studio.mittwald.de/app/projects/${project.data.id}/apps`}
            >
              App installieren
            </Link>
          </Content>
        </Alert>
      )}
      {project.data.appInstallations.length > 0 && (
        <RadioGroup
          onChange={setSelectedAppInstallation}
          value={selectedAppInstallation}
        >
          <Label>App-Installation auswählen</Label>
          {project.data.appInstallations.map((a) => {
            return (
              <RadioButton key={a.id} value={a.id} isDisabled={!a.phpVersion}>
                <Text>{a.description}</Text>
                <Content>
                  {a.phpVersion ? (
                    <>PHP {a.phpVersion}</>
                  ) : (
                    <>kein PHP installiert</>
                  )}
                  <br />
                  <strong>
                    <small>{a.shortId}</small>
                  </strong>
                </Content>
              </RadioButton>
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};
