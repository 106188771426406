import { useSuspenseQuery } from "@tanstack/react-query";
import { getProject } from "../openapi/api-client.ts";
import { LoadProjectError } from "../errors/LoadProjectError.ts";

export const useProject = (projectId?: string, extensionInstanceId?: string) =>
  useSuspenseQuery({
    queryKey: ["project", { projectId }],
    queryFn: async () => {
      if (!projectId) {
        throw new Error("project id not set");
      }

      if (!extensionInstanceId) {
        throw new Error("extension instance id not set");
      }

      const response = await getProject({
        path: { projectId },
        header: { extensionInstanceId },
      });

      if (!response.data) {
        throw new LoadProjectError(
          `error while fetching project: ${response.error.message}`,
        );
      }

      return response.data;
    },
  });
