import Heading from "@mittwald/flow-react-components/Heading";
import { useProjectPHPStatistics } from "../hooks/useProjectPHPStatistics";
import ProgressBar from "@mittwald/flow-react-components/ProgressBar";
import Text from "@mittwald/flow-react-components/Text";
import Header from "@mittwald/flow-react-components/Header";
import ContextualHelp, {
  ContextualHelpTrigger,
} from "@mittwald/flow-react-components/ContextualHelp";
import Button from "@mittwald/flow-react-components/Button";
import Link from "@mittwald/flow-react-components/Link";
import Modal, { ModalTrigger } from "@mittwald/flow-react-components/Modal";
import { IconDelete } from "@mittwald/flow-react-components/Icons";
import Content from "@mittwald/flow-react-components/Content";
import Section from "@mittwald/flow-react-components/Section";
import ActionGroup from "@mittwald/flow-react-components/ActionGroup";
import Action from "@mittwald/flow-react-components/Action";
import Label from "@mittwald/flow-react-components/Label";

export const OpCacheStats = (props: {
  projectId: string;
  extensionInstanceId: string;
}) => {
  const phpStats = useProjectPHPStatistics(
    props.projectId,
    props.extensionInstanceId,
  );

  return (
    <>
      <Header>
        <Heading level={3}>
          OPcache
          <ContextualHelpTrigger>
            <Button />
            <ContextualHelp>
              <Heading>OPcache</Heading>
              <Text>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam.
              </Text>
              <Link
                href="https://www.php.net/manual/de/ini.list.php"
                target="_blank"
              >
                Mehr erfahren
              </Link>
            </ContextualHelp>
          </ContextualHelpTrigger>
        </Heading>
        <ModalTrigger>
          <Button color="danger" variant="soft" isDisabled>
            OPcache leeren
          </Button>
          <Modal size="s">
            <Heading>
              <IconDelete />
              Möchtest du den OPcache wirklich leeren?
            </Heading>
            <Content>
              <Section>
                <Text>
                  Das Leeren des OPcache sorgt initial für einen verlängerten
                  Seitenaufruf.
                </Text>
              </Section>
            </Content>
            <ActionGroup>
              <Action closeOverlay="Modal">
                <Action>
                  <Button color="danger">OPcache leeren</Button>
                </Action>
                <Button variant="soft" color="secondary">
                  Abbrechen
                </Button>
              </Action>
            </ActionGroup>
          </Modal>
        </ModalTrigger>
      </Header>
      <ProgressBar
        value={(phpStats.data.opcache?.usage ?? 0) / 1024 / 1024}
        maxValue={(phpStats.data.opcache?.size ?? 0) / 1024 / 1024}
        showMaxValue
        formatOptions={{ style: "unit", unit: "megabyte" }}
      >
        <Label>Aktuelle OPcache-Auslastung</Label>
      </ProgressBar>
    </>
  );
};
