import Heading from "@mittwald/flow-react-components/Heading";
import Section from "@mittwald/flow-react-components/Section";
import Text from "@mittwald/flow-react-components/Section";
import { useRouteError, useSearchParams } from "react-router-dom";
import { AuthenticationError } from "../errors/AuthenticationError.ts";
import CodeBlock from "@mittwald/flow-react-components/CodeBlock";
import Link from "@mittwald/flow-react-components/Link";
import { MissingParamError } from "../errors/MissingParamError.ts";
import Accordion from "@mittwald/flow-react-components/Accordion";
import Content from "@mittwald/flow-react-components/Content";
import LayoutCard from "@mittwald/flow-react-components/LayoutCard";
import { Footer } from "./Footer.tsx";
import { SavingIniError } from "../errors/SavingIniError.ts";
import { LoadAppPHPSettingsError } from "../errors/LoadAppPHPSettingsError.ts";
import { LoadProjectError } from "../errors/LoadProjectError.ts";
import { LoadProjectPHPSettings } from "../errors/LoadProjectPHPSettings.ts";
import InlineCode from "@mittwald/flow-react-components/InlineCode";

export const ErrorElement = () => {
  const error = useRouteError();
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("projectId");
  const extensionInstanceId = searchParams.get("extensionInstanceId");

  return (
    <>
      <LayoutCard>
        <Section>
          <Heading level={1}>Ein Fehler ist aufgetreten</Heading>
          {error instanceof AuthenticationError && (
            <>
              <Text>
                Wir konnten dich nicht authentifizieren. Bitte öffne die
                Extension erneut über das mStudio.
              </Text>
              <Link
                href={`https://studio.mittwald.de${projectId ? `/app/projects/${projectId}/extensions${extensionInstanceId ? `/${extensionInstanceId}` : ""}` : ""}`}
              >
                zum mStudio
              </Link>
            </>
          )}
          {error instanceof MissingParamError && (
            <>
              <Text>
                Es fehlen Parameter in der URL. Bitte öffne die Extension erneut
                über das mStudio.
              </Text>
              <Link
                href={`https://studio.mittwald.de${projectId ? `/app/projects/${projectId}/extensions${extensionInstanceId ? `/${extensionInstanceId}` : ""}` : ""}`}
              >
                zum mStudio
              </Link>
            </>
          )}
          {error instanceof SavingIniError && (
            <>
              <Text>
                Wir konnten deine Konfiguration nicht speichern. Folgende
                Fehlermeldung wurde vom Server geliefert:
              </Text>
              <CodeBlock code={error.message} copyable />
              <Text>
                Bitte überprüfe, ob du aus dieser Fehlermeldung das Problem
                ableiten kannst. Falls nicht, melde dich mit den Fehlerdetails
                bei unserem Support, dann unterstützen wir dich gerne.
              </Text>
              <Link
                href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent("Fehler beim Speichern der PHP-Einstellungen")}&body=${encodeURIComponent(`Folgender Fehler ist beim Speichern der PHP-Einstellungen aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`)}`}
              >
                support@hueskehilker.de
              </Link>
            </>
          )}
          {error instanceof LoadProjectError && (
            <>
              <Text>
                Wir konnten dein Projekt nicht aus dem mStudio abrufen. Folgende
                Fehlermeldung wurde vom Server geliefert:
              </Text>
              <CodeBlock code={error.message} copyable />
              <Text>
                Möglicherweise handelt es sich dabei nur um ein temporäres
                Problem. Probiere es einfach in einigen Augenblicken nochmal.
              </Text>
              <Link href={window.location.href}>Nochmal versuchen</Link>
              <Text>
                Wenn sich das Problem nicht von selbst löst, melde dich mit den
                Fehlerdetails bei unserem Support, dann unterstützen wir dich
                gerne.
              </Text>
              <Link
                href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent("Fehler beim Laden des Projektes aus dem mStudio")}&body=${encodeURIComponent(`Folgender Fehler ist beim Laden des Projektes aus dem mStudio aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`)}`}
              >
                support@hueskehilker.de
              </Link>
            </>
          )}
          {error instanceof LoadAppPHPSettingsError && (
            <>
              <Text>
                Wir konnten die PHP-Einstellungen deiner App nicht laden.
                Folgende Fehlermeldung wurde vom Server geliefert:
              </Text>
              <CodeBlock code={error.message} copyable />
              <Text>
                Häufig ist das Problem eine ungültige
                <InlineCode>.user.ini</InlineCode>-Datei in deinem
                App-Verzeichnis. Bitte überprüfe die Syntax und lösche die Datei
                ggf. einfach.
              </Text>
              <Link href={window.location.href}>Nochmal versuchen</Link>
              <Text>
                Wenn sich das Problem nicht lösen lässt, melde dich mit den
                Fehlerdetails bei unserem Support, dann unterstützen wir dich
                gerne.
              </Text>
              <Link
                href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent("Fehler beim Laden der PHP-Einstellungen der App")}&body=${encodeURIComponent(`Folgender Fehler ist beim Laden des Projektes aus dem mStudio aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`)}`}
              >
                support@hueskehilker.de
              </Link>
            </>
          )}
          {error instanceof LoadProjectPHPSettings && (
            <>
              <Text>
                Wir konnten die PHP-Einstellungen deines Projektes nicht laden.
                Folgende Fehlermeldung wurde vom Server geliefert:
              </Text>
              <CodeBlock code={error.message} copyable />
              <Text>
                Häufig ist das Problem eine ungültige{" "}
                <InlineCode>php.ini</InlineCode> in deinem Projekt. Bitte
                überprüfe die Syntax und leere die Datei ggf. einfach. Du
                findest die Datei unter dem Pfad{" "}
                <InlineCode>/etc/php/php.ini</InlineCode>
              </Text>
              <Link href={window.location.href}>Nochmal versuchen</Link>
              <Text>
                Wenn sich das Problem nicht lösen lässt, melde dich mit den
                Fehlerdetails bei unserem Support, dann unterstützen wir dich
                gerne.
              </Text>
              <Link
                href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent("Fehler beim Laden der PHP-Einstellungen des Projektes")}&body=${encodeURIComponent(`Folgender Fehler ist beim Laden der PHP-Einstellungen des Projektes aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`)}`}
              >
                support@hueskehilker.de
              </Link>
            </>
          )}

          {!(
            error instanceof MissingParamError ||
            error instanceof AuthenticationError ||
            error instanceof LoadAppPHPSettingsError ||
            error instanceof LoadProjectError ||
            error instanceof LoadProjectPHPSettings ||
            error instanceof SavingIniError
          ) &&
            error instanceof Error && (
              <>
                <Text>
                  Bitte lade die Seite neu, um es erneut zu versuchen. Folgender
                  Fehler ist aufgetreten:
                </Text>
                <CodeBlock code={error.message} copyable />
                <Text>
                  Möglicherweise handelt es sich dabei nur um ein temporäres
                  Problem. Probiere es einfach in einigen Augenblicken nochmal.
                </Text>
                <Link href={window.location.href}>Seite neuladen</Link>
                <Text>
                  Wenn das Problem weiterhin auftritt, melde dich mit den
                  Fehlerdetails bei unserem Support, dann unterstützen wir dich
                  gerne.
                </Text>
                <Link
                  href={`mailto:support@hueskehilker.de?subject=${encodeURIComponent("Fehler in der Extension")}&body=${encodeURIComponent(`Folgender Fehler ist bei der Benutzung der Extension aufgetreten:\n\n${error.stack}\n\nDer Fehler ist auf folgender Seite aufgetreten:\n\n${window.location.href}`)}`}
                >
                  support@hueskehilker.de
                </Link>
              </>
            )}

          {error instanceof Error && error.stack && (
            <Accordion variant="outline">
              <Heading>Fehlerdetails für den Support</Heading>
              <Content>
                <Section>
                  <Text>
                    Unsere Entwickler freuen sich, wenn du ihnen diese
                    Fehlerdetails mitteilst.
                  </Text>
                  <CodeBlock
                    copyable
                    code={`${window.location.href}\n\n${error.stack}`}
                  />
                </Section>
              </Content>
            </Accordion>
          )}
        </Section>
      </LayoutCard>
      <LayoutCard>
        <Footer />
      </LayoutCard>
    </>
  );
};
