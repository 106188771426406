import { useEffect, useState } from "react";
import { socket } from "../socket";
import CodeBlock from "@mittwald/flow-react-components/CodeBlock";
import Text from "@mittwald/flow-react-components/Text";
import LoadingSpinner from "@mittwald/flow-react-components/LoadingSpinner";
import Alert from "@mittwald/flow-react-components/Alert";
import Heading from "@mittwald/flow-react-components/Heading";
import Content from "@mittwald/flow-react-components/Content";
import Link from "@mittwald/flow-react-components/Link";

export const ErrorLog = (props: {
  appInstallationId: string;
  projectId: string;
  extensionInstanceId: string;
}) => {
  const [isReady, setIsReady] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    console.log("connecting");
    socket.connect();

    const onLog = (event: string) => {
      setLogs((prev) => [event, ...prev.slice(0, 99)]);
    };

    socket.on("connect", () => {
      console.log("connected");

      socket.emit("subscribe", {
        projectId: props.projectId,
        appInstallationId: props.appInstallationId,
        extensionInstanceId: props.extensionInstanceId,
      });
    });

    socket.on("sshConnected", () => {
      setIsReady(true);
    });

    socket.on("log", onLog);

    return () => {
      socket.off("log", onLog);
      socket.disconnect();
      console.log("disconnected");
    };
  }, []);

  return (
    <>
      <Alert>
        <Heading>Dieses Feature befindet sich in der Testphase</Heading>
        <Content>
          Melde dich mit Verbesserungsvorschlägen gerne bei uns. Wir freuen uns
          auf dein Feedback.
          <Link href="mailto:support@hueskehilker.de">
            support@hueskehilker.de
          </Link>
        </Content>
      </Alert>
      {!isReady && (
        <div style={{ display: "flex" }}>
          <LoadingSpinner height={100} />{" "}
          <Text style={{ marginLeft: 10 }}>Verbinde mit Server ...</Text>
        </div>
      )}
      {isReady && (
        <>
          <div style={{ display: "flex" }}>
            <LoadingSpinner height={100} />{" "}
            <Text style={{ marginLeft: 10 }}>Empfange Live Error-Log ...</Text>
          </div>
          <CodeBlock
            code={`...${logs.length == 0 ? "\n" : "\n\n"}${logs.join("\n")}`}
          />
        </>
      )}
    </>
  );
};
