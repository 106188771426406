import Section from "@mittwald/flow-react-components/Section";
import "./Editor.css";
import LayoutCard from "@mittwald/flow-react-components/LayoutCard";
import Heading from "@mittwald/flow-react-components/Heading";
import { useSearchParams } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { authenticationATReK } from "../openapi/api-client.ts";
import { TempAppSelection } from "../components/TempAppSelection.tsx";
import { AuthenticationError } from "../errors/AuthenticationError.ts";
import { TestForm } from "../components/TestForm.tsx";
import { Footer } from "../components/Footer.tsx";
import { FormSkeleton } from "../components/FormSkeleton.tsx";
import { AppSelectionSkeleton } from "../components/AppSelectionSkeleton.tsx";
import LoadingSpinner from "@mittwald/flow-react-components/LoadingSpinner";
import Text from "@mittwald/flow-react-components/Text";
import { MissingParamError } from "../errors/MissingParamError.ts";
import { OpCacheStats } from "../components/OpCacheStats.tsx";
import { OPCacheStatsSkeleton } from "../components/OPCacheStatsSkeleton.tsx";
import { useFlag, useUnleashContext } from "@unleash/proxy-client-react";

let authenticationRan = false;

export const Editor = () => {
  const [searchParams] = useSearchParams();
  const updateContext = useUnleashContext();
  const [appInstallationId, setAppInstallationId] = useState<string | null>(
    null,
  );
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [authenticationError, setAuthenticationError] = useState<Error | null>(
    null,
  );
  if (authenticationError) {
    throw authenticationError;
  }

  const projectId = searchParams.get("projectId");
  const extensionInstanceId = searchParams.get("extensionInstanceId");
  const atrek = searchParams.get("atrek");
  const userId = searchParams.get("userId");

  if (!atrek) {
    throw new MissingParamError("ATReK nicht gegeben");
  }

  if (!userId) {
    throw new MissingParamError("UserId nicht gegeben");
  }

  if (!projectId) {
    throw new MissingParamError("ProjectId nicht gegeben");
  }

  if (!extensionInstanceId) {
    throw new MissingParamError("ExtensionInstanceId nicht gegeben");
  }

  useEffect(() => {
    if (authenticationRan) {
      return;
    } else {
      authenticationRan = true;
    }

    updateContext({ userId });

    if (atrek == "xxx") {
      setAuthenticated(true);
      return;
    }

    const authenticate = async () => {
      const result = await authenticationATReK({
        userId,
        atrek,
        extensionInstanceId,
      });

      if (result.response.status == 204) {
        setAuthenticated(true);

        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set("atrek", "xxx");
        window.history.replaceState({}, "", currentUrl.toString());

        return;
      }

      setAuthenticationError(
        new AuthenticationError(
          `could not authenticate: ${JSON.stringify(result.error)}`,
        ),
      );
    };

    authenticate();
  }, []);

  const showOPCacheFeatureFlag = useFlag("opcache");

  return (
    <>
      <LayoutCard>
        <Section>
          <Heading level={1}>PHP-Einstellungen</Heading>
          {!authenticated && (
            <>
              <div style={{ display: "flex" }}>
                <LoadingSpinner height={100} />{" "}
                <Text style={{ marginLeft: 10 }}>Authentifizierung ...</Text>
              </div>
            </>
          )}

          {authenticated && (
            <Suspense
              fallback={
                <>
                  <AppSelectionSkeleton />
                </>
              }
            >
              <TempAppSelection
                projectId={projectId}
                extensionInstanceId={extensionInstanceId}
                appInstallationSelected={setAppInstallationId}
              />
            </Suspense>
          )}
        </Section>
      </LayoutCard>

      {appInstallationId && (
        <LayoutCard>
          <Suspense
            fallback={
              <Section>
                <FormSkeleton />
              </Section>
            }
          >
            <TestForm
              key={appInstallationId}
              projectId={projectId}
              appInstallationId={appInstallationId}
              extensionInstanceId={extensionInstanceId}
            />
          </Suspense>
        </LayoutCard>
      )}

      {authenticated && showOPCacheFeatureFlag && (
        <LayoutCard>
          <Section>
            <Heading>Globale PHP-Einstellungen</Heading>
            <Text>
              Änderungen, die du hier machst, wirken sich auf das gesamte
              Projekt bzw. alle Apps aus.
            </Text>
            <Suspense fallback={<OPCacheStatsSkeleton />}>
              <OpCacheStats
                projectId={projectId}
                extensionInstanceId={extensionInstanceId}
              />
            </Suspense>
          </Section>
        </LayoutCard>
      )}

      <LayoutCard>
        <Footer />
      </LayoutCard>
    </>
  );
};
