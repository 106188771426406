import { useSuspenseQuery } from "@tanstack/react-query";
import { getProjectPHPStatistics } from "../openapi/api-client.ts";
import { LoadProjectPHPStatistics } from "../errors/LoadProjectPHPStatistics.ts";

export const useProjectPHPStatistics = (
  projectId?: string,
  extensionInstanceId?: string,
) =>
  useSuspenseQuery({
    queryKey: ["projectPHPStatistics", { projectId }],
    queryFn: async () => {
      if (!projectId) {
        throw new Error("project id not set");
      }

      if (!extensionInstanceId) {
        throw new Error("extension instance id not set");
      }

      const response = await getProjectPHPStatistics({
        path: { projectId },
        header: { extensionInstanceId },
      });

      if (!response.data) {
        throw new LoadProjectPHPStatistics(
          `error while fetching project php statistics: ${response.error.message}`,
        );
      }

      return response.data;
    },
  });
