import Link from "@mittwald/flow-react-components/Link";
import Section from "@mittwald/flow-react-components/Section";
import Text from "@mittwald/flow-react-components/Text";

export const Footer = () => {
  return (
    <Section>
      <Text>
        Dir fehlt etwas, du hast Fragen oder Feedback? Bitte melde dich über{" "}
        <Link href="mailto:support@hueskehilker.de">
          support@hueskehilker.de
        </Link>
        !
      </Text>
    </Section>
  );
};
